import client from '../thinMintClient'
import { InvestmentForm, ServerInvestment, InvestmentPerk } from './types'
import { resolveInvestmentDown, resolveInvestmentUp } from './resolver'

export const baseEndpoint = '/investments'

export const getInvestments = () =>
  client
    .get(`${baseEndpoint}/`)
    .then((res: { data: { results: ServerInvestment[] } }) =>
      res.data.results.map(resolveInvestmentDown)
    )

export const createInvestment = (form: InvestmentForm) =>
  client
    .post(`${baseEndpoint}/`, resolveInvestmentUp(form))
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))

export const updateInvestment = (investmentId: string, form: InvestmentForm) =>
  client
    .put(`${baseEndpoint}/${investmentId}/`, resolveInvestmentUp(form))
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))

export const getUserPerks = () =>
  client
    .get(`/user-perks/`)
    .then((res: { data: { results: InvestmentPerk[] } }) => {
      return res.data.results
    })

export const cancelInvestment = (investmentId: string) =>
  client
    .post(`${baseEndpoint}/${investmentId}/cancel_investment/`, {})
    .then((res: { data: { id: string } }) => res.data)

export const reconfirmInvestment = (investmentId: string) =>
  client
    .post(`${baseEndpoint}/${investmentId}/reconfirm/`, {})
    .then((res: { data: ServerInvestment }) => resolveInvestmentDown(res.data))
